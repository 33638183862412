import router from "@/router";
import Axios from "axios";
import { Message } from "element-ui";
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
//响应拦截
Axios.interceptors.response.use(
  response => {
    nprogress.done();  // 进度完成




    return response;
  },
  error => {
    if (error.response.data.code === 401) {
      Message.error(error.response.data.msg)
      router.push({ name: "login" })
    } else if (error.response.data.code != 200) {

      router.push({ name: "login" })
    }

    if (error.response.data.status === 404) {
      Message.error(`${error.response.config.url} 404!`)
    }
  }
);



//添加token
Axios.interceptors.request.use(async function (conf) {
  conf.headers.token = `${localStorage.getItem('token')}`
  nprogress.start();  // 开启进度条
  return conf;
});
