var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"form",attrs:{"size":"mini","model":_vm.value,"label-width":"80px"},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._l((_vm.processingColumns),function(item){return [(item.editConfig && item.editConfig.type === 'slot')?_c('el-form-item',{key:item.name,attrs:{"label":item.label}},[_vm._t(item.name)],2):(item.editConfig && item.editConfig.type === 'yesNo')?_c('el-form-item',{key:item.name + 'yesNo',attrs:{"label":item.label}},[_c('el-radio-group',{attrs:{"value":_vm.value[item.name]},on:{"input":(e) => {
          _vm.$set(_vm.value, item.name, e);
        }}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":0}},[_vm._v("否")])],1)],1):(item.editConfig && item.editConfig.type === 'dateTime')?_c('el-form-item',{key:item.name + 'dateTime',attrs:{"label":item.label}},[_c('el-date-picker',{attrs:{"value":_vm.value[item.name],"value-format":"yyyy-MM-dd HH:mm:ss","type":"datetime","placeholder":"选择日期时间"},on:{"input":(e) => {
          _vm.$set(_vm.value, item.name, e);
        }}})],1):(item.editConfig && item.editConfig.type === 'dictionary')?_c('el-form-item',{key:item.name + 'dictionary',attrs:{"label":item.label}},[_c('GDictionary',{attrs:{"value":_vm.value[item.name],"name":item.editConfig.dictionaryName ? item.editConfig.dictionaryName : item.name},on:{"input":(e) => {
          _vm.$set(_vm.value, item.name, e);
        }}})],1):(item.editConfig && item.editConfig.type === 'uploadPic')?_c('el-form-item',{key:item.name + 'uploadPic',attrs:{"label":item.label}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"/api/upload","show-file-list":false,"on-success":(event, file, fileList) => {
          _vm.handleAvatarSuccess(event, file, fileList, item.name);
        }}},[(_vm.value[item.name])?_c('img',{staticClass:"avatar",attrs:{"src":_vm.value[item.name]}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1):(item.editConfig && item.editConfig.type === 'textarea')?_c('el-form-item',{key:item.name + 'textarea',attrs:{"label":item.label}},[_c('el-input',{attrs:{"disabled":item.editConfig && item.editConfig.disabled,"autosize":{ minRows: 3 },"resize":"none","show-word-limit":"","maxlength":500,"type":"textarea","value":_vm.value[item.name]},on:{"input":(e) => {
            _vm.$set(_vm.value, item.name, e);
          }}})],1):(item.editConfig && item.editConfig.type === 'text')?_c('el-form-item',{key:item.name + 'text',attrs:{"label":item.label}},[_c('div',[_vm._v(_vm._s(_vm.value[item.name]))])]):_c('el-form-item',{key:item.name + 'input',attrs:{"label":item.label}},[_c('el-input',{attrs:{"disabled":item.editConfig && item.editConfig.disabled,"value":_vm.value[item.name]},on:{"input":(e) => {
          _vm.$set(_vm.value, item.name, e);
        }}})],1)]}),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[(_vm.$route.query.id)?[_vm._v("立即修改")]:[_vm._v("立即创建")]],2),_c('el-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }