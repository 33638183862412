<template>
  <el-select @change="change" :value="value" placeholder="">
    <el-option v-for="(item, index) in dictionary[name]" :key="index" :label="item[labelName]"
      :value="item[valueName]"></el-option>
  </el-select>
</template>

<script>

import { mapState } from "vuex";
export default {
  computed: mapState(["dictionary"]),
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    labelName: {
      type: String,
      default: "label",
    },

    valueName: {
      type: String,
      default: "value",
    },
    url: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      test: "",
      option: [],
    };
  },
  methods: {
    change(e) {
      this.$emit("input", e)

    },

  }

};
</script>

<style></style>