<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import notificationsApi from '@/api/module/notifications'
import baseApi from '@/api/base'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      instanceNotification: null
    }
  },
  methods: {
    async findNotSent() {
      let res = await notificationsApi.findNotSent()

      for (let i = 0; i < res.data.data.length; i++) {
        // console.log(res.data.data[i], "res.data.data[i]")
        let time = dayjs(res.data.data[i].reminderTime).unix() - dayjs().unix()
        // console.log(time)
        if (time < 0) {
          //设置超时
          res.data.data[i].state = 3
          await baseApi.update("notifications", res.data.data[i])
        } else {
          //设置提醒
          this.sendNotification(time, res.data.data[i].title, res.data.data[i].text, res.data.data[i])
        }
      }
    },
    //发送通知
    sendNotification(time, title, text, data) {
      setTimeout(() => {
        var instanceNotification = new Notification(title, { "icon": "", "body": text, "requireInteraction": true });
        instanceNotification.onshow = async function () {
          data.state = 2
          await baseApi.update("notifications", data)
          //3s后自动关闭通知
          // setTimeout(function () {
          //   instanceNotification.close()
          // }, 3 * 1000);
        };
      }, time * 1000);


    },
    //设置
    setUpNotifications() {
      this.instanceNotification = Notification || window.Notification;
      if (this.instanceNotification) {
        var permissionNow = this.instanceNotification.permission;
        if (permissionNow === 'granted') {//允许通知
  
        } else if (permissionNow === 'default') {
          this.getPermission();
        } else if (permissionNow === 'denied') {
          this.getPermission();
        } else {
          this.getPermission();
        }
      }

    },
    //获取权限
    getPermission() {
      this.instanceNotification.requestPermission(function (PERMISSION) {
        if (PERMISSION === 'granted') {
          console.log('用户允许通知了!!!');
        } else {
          console.log('用户拒绝了你!!!');
        }
      });
    }
  },
  created() {
    this.setUpNotifications()
    this.$store.dispatch("getDictionary");
    //获取通知
    this.findNotSent()
  }
}
</script>
<style>
/* //tips宽度 */
.el-tooltip__popper {
  max-width: 80%;
}

/* 去掉code padding */
.jv-container .jv-code {
  padding: 0 !important;
}
</style>