import Vue from 'vue'
import Vuex from 'vuex'
import baseApi from '@/api/base'
Vue.use(Vuex)
import { Message } from 'element-ui'
export default new Vuex.Store({
  state: {
    dictionary: {}
  },
  getters: {
  },
  mutations: {
    setDictionary(state, data) {
      state.dictionary = data;
    }
  },
  actions: {
    //获取字典数据
    getDictionary(context) {
      baseApi.getList('dictionary').then(res => {
        if (res.data.code === 200) {
          let obj = {}
          for (let i = 0; i < res.data.data.length; i++) {

            obj[res.data.data[i].dictionaryName] = JSON.parse(res.data.data[i].dictionaryValue)
          }
          context.commit("setDictionary", obj);
        } else {
          Message.error('获取数据失败')
        }
      })
    }
  },
  modules: {
  }
})
