<template>
  <div>
    <div class="btns">
      <el-button size="mini" type="primary" v-if="isAdd" @click="toEdit"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="isListLoading"
      size="mini"
      @selection-change="handleSelectionChange"
      border
      :data="tableData"
      style="width: 100%"
    >
      <template v-for="item in columns">
        <template v-if="item.listConfig && item.listConfig.type === 'dateTime'">
          <el-table-column
            :prop="item.name"
            :label="item.label"
            :key="item.name"
          >
            <template slot-scope="scope">
              {{ scope.row[item.name] | $dateTime }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.listConfig && item.listConfig.type === 'img'">
          <el-table-column
            align="center"
            :prop="item.name"
            :width="item.listConfig.width ? item.listConfig.width : '65px'"
            :label="item.label"
            :key="item.name"
          >
            <template slot-scope="scope">
              <viewer>
                <img class="img" :src="scope.row[item.name]" />
              </viewer>
            </template>
          </el-table-column>
        </template>
        <template
          v-else-if="item.listConfig && item.listConfig.type === 'yesNo'"
        >
          <el-table-column
            :prop="item.name"
            align="center"
            :width="item.listConfig.width ? item.listConfig.width : '80px'"
            :label="item.label"
            :key="item.name"
          >
            <template slot-scope="scope">
              <el-switch
                @change="changeYesNo(scope.row, scope.row[item.name])"
                v-model="scope.row[item.name]"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>
        </template>

        <template
          v-else-if="item.listConfig && item.listConfig.type === 'dictionary'"
        >
          <el-table-column
            :prop="item.name"
            :width="item.listConfig.width"
            :label="item.label"
            :key="item.name"
          >
            <template slot-scope="scope">
              {{
                selectLabel(
                  item.dictionaryName ? item.dictionaryName : item.name,
                  scope.row[item.name]
                )
              }}
            </template>
          </el-table-column>
        </template>

        <template
          v-else-if="item.listConfig && item.listConfig.type === 'slot'"
        >
          <el-table-column
            :prop="item.name"
            :width="item.listConfig.width"
            :label="item.label"
            :key="item.name"
          >
            <template slot-scope="scope">
              <slot
                :name="item.name"
                :data="scope.row"
                :index="scope.$index"
              ></slot>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            show-overflow-tooltip
            :prop="item.name"
            :width="item.listConfig && item.listConfig.width"
            :label="item.label"
            :key="item.name"
          >
          </el-table-column>
        </template>
      </template>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" v-if="isInfo" @click="info(scope.row)"
            >查看</el-button
          >
          <el-button v-if="isEdit" size="mini" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            v-if="isDel"
            v-loading="isDelLoading"
            type="danger"
            @click="del(scope.row)"
            >删除</el-button
          >
          <slot name="appendAction" :scope="scope"></slot>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" style="margin-top: 5px" justify="end">
      <el-pagination
        hide-on-single-page
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-sizes="[10, 15, 20]"
        :page-size="size"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-row>

    <el-dialog
      :close-on-click-modal="false"
      title="详情"
      destroy-on-close
      v-if="isInfo"
      :visible.sync="isInfoModel"
      width="80%"
    >
      <slot name="infoModel" :infoData="infoData"></slot>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="isInfoModel = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import baseApi from "@/api/base";
import { mapState } from "vuex";
export default {
  name: "ListView",
  computed: mapState(["dictionary"]),
  props: {
    className: {
      type: String,
      defaluat: "",
    },
    //列配置
    columns: {
      type: Array,
      default: () => [],
    },
    //是否允许添加
    isAdd: {
      type: Boolean,
      default: true,
    },
    //是否允许删除
    isDel: {
      type: Boolean,
      default: true,
    },
    //是否允许编辑
    isEdit: {
      type: Boolean,
      default: true,
    },
    //是否查看详情
    isInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      isDelLoading: false,
      isListLoading: false,
      infoData: {},
      isInfoModel: false,
      total: 0,
      size: 10,
      page: 1,
    };
  },
  methods: {
    //更新是否
    async changeYesNo(row) {
      let res = await baseApi.update(this.className, row);
      if (res.data.code === 200) {
        this.$message.success("更新成功");
      } else {
        this.$message.error("更新失败");
      }
    },
    selectLabel(name, value) {
      return this.dictionary[name]?.find((item) => item.value === value)?.label;
    },
    handleSizeChange(size) {
      this.size = size;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    //跳转编辑页面
    toEdit(row) {
      this.$router.push({
        name: `${this.className}Edit`,
        query: { id: row.id },
      });
    },
    async del(row) {
      this.isDelLoading = true;
      let res = await baseApi.del(this.className, row);
      if (res.data.code === 200) {
        this.$message.success("删除成功");
        this.getList();
      }
      this.isDelLoading = false;

    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getList() {
      this.isListLoading = true;
      let res = await baseApi.getList(this.className, {
        current: this.page,
        pageSize: this.size,
      });
      if (res && res.data.code === 200) {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      }

      this.isListLoading = false;
    },
    info(data) {
      this.infoData = data;
      this.isInfoModel = true;

    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.btns {
  margin-bottom: 10px;
}

.img {
  width: 100%;
  cursor: pointer;
}
</style>