import Axios from 'axios'
const baseUrl = process.env.VUE_APP_BASE_URL;
export default {
    // 获取未发送通知
    findNotSent(data) {
        return Axios({
            url: `/${baseUrl}/notifications/findNotSent`,
            method: "get",
            data
        });
    }
}