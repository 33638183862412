
import Edit from './GEdit.vue'

import List from './GList.vue'

import GDictionary from './GDictionary.vue'



let Vue = null
export default {
    install(_Vue) {
        if (Vue !== _Vue) {
            Vue = _Vue
        }
        // 编辑
        Vue.component('GEdit', Edit)
        // 列表
        Vue.component('GList', List)

        Vue.component('GDictionary', GDictionary)

    }
}
