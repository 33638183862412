import Axios from 'axios'
const baseUrl = process.env.VUE_APP_BASE_URL;
export default {
    // 获取列表
    getList(className, data) {
        return Axios({
            url: `/${baseUrl}/${className}/base`,
            method: "get",
            params:data
        });
    },
    //删除
    del(className, data) {
        return Axios({
            url: `/${baseUrl}/${className}/base/${data.id}`,
            method: "delete"
        });
    },
    //新增
    add(className, data) {
        return Axios({
            url: `/${baseUrl}/${className}/base`,
            method: "post",
            data
        });
    },
    //获取详情
    getInfo(className, data) {
        return Axios({
            url: `/${baseUrl}/${className}/base/${data.id}`,
            method: "get",
            data
        });
    },
    //修改
    update(className, data) {
        return Axios({
            url: `/${baseUrl}/${className}/base/${data.id}`,
            method: "put",
            data
        });
    }
}