<template>
  <div>
    <el-form @submit.native.prevent="submit" size="mini" ref="form" :model="value" label-width="80px">
      <template v-for="item in processingColumns">
        <el-form-item v-if="item.editConfig && item.editConfig.type === 'slot'" :label="item.label" :key="item.name">
          <slot :name="item.name"></slot>
        </el-form-item>

        <el-form-item v-else-if="item.editConfig && item.editConfig.type === 'yesNo'" :label="item.label"
          :key="item.name + 'yesNo'">
          <el-radio-group @input="(e) => {
            $set(value, item.name, e);
          }
          " :value="value[item.name]">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-else-if="item.editConfig && item.editConfig.type === 'dateTime'" :label="item.label"
          :key="item.name + 'dateTime'">
          <el-date-picker @input="(e) => {
            $set(value, item.name, e);
          }
          " :value="value[item.name]" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item v-else-if="item.editConfig && item.editConfig.type === 'dictionary'" :label="item.label"
          :key="item.name + 'dictionary'">
          <GDictionary @input="(e) => {
            $set(value, item.name, e);
          }
          " :value="value[item.name]"
            :name="item.editConfig.dictionaryName ? item.editConfig.dictionaryName : item.name"></GDictionary>
        </el-form-item>

        <el-form-item v-else-if="item.editConfig && item.editConfig.type === 'uploadPic'" :label="item.label"
          :key="item.name + 'uploadPic'">
          <el-upload class="avatar-uploader" action="/api/upload" :show-file-list="false" :on-success="(event, file, fileList) => {
            handleAvatarSuccess(event, file, fileList, item.name);
          }
          ">
            <img v-if="value[item.name]" :src="value[item.name]" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item v-else-if="item.editConfig && item.editConfig.type === 'textarea'" :label="item.label"
          :key="item.name + 'textarea'">
          <el-input :disabled="item.editConfig && item.editConfig.disabled" :autosize="{ minRows: 3 }" resize="none"
            show-word-limit :maxlength="500" type="textarea" @input="(e) => {
              $set(value, item.name, e);
            }
            " :value="value[item.name]"></el-input>
        </el-form-item>

        <el-form-item v-else-if="item.editConfig && item.editConfig.type === 'text'" :label="item.label"
          :key="item.name + 'text'">
          <div>{{ value[item.name] }}</div>
        </el-form-item>
        <el-form-item v-else :label="item.label" :key="item.name + 'input'">
          <el-input :disabled="item.editConfig && item.editConfig.disabled" @input="(e) => {
            $set(value, item.name, e);
          }
          " :value="value[item.name]"></el-input>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button type="primary" @click="submit">
          <template v-if="$route.query.id">立即修改</template>
          <template v-else>立即创建</template>
        </el-button>
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import baseApi from "@/api/base";
export default {
  name: "EditView",
  data() {
    return {
      imageUrl: "",
    };
  },
  props: {
    className: {
      type: String,
      default: "",
    },
    //列配置
    columns: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    processingColumns() {
      return this.columns.filter((item) => !item.editConfig?.hide);
    },
  },
  methods: {
    handleAvatarSuccess(res, file, fileList, name) {
      if (res) {
        this.$set(this.value, name, URL.createObjectURL(file.raw));
      }

      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    submit() {
      if (this.value.id) {
        this.update({ ...this.value });
      } else {
        this.add({ ...this.value });
      }
    },
    async add(data) {
      let res = await baseApi.add(this.className, data);
      if (res.data.code === 200) {
        this.$message.success("新增成功！");
        this.$router.replace({ name: this.className });
      }
    },
    async update(data) {
      let res = await baseApi.update(this.className, data);
      if (res.data.code === 200) {
        this.$message.success("更新成功");
        this.$router.replace({ name: this.className });
      }
    },
    //获取详细信息
    async getInfo() {
      let res = await baseApi.getInfo(this.className, {
        id: this.$route.query.id,
      });
      if (res && res.data.code === 200) {
        if (this.$listeners.afterGetInfo) {
          this.$listeners.afterGetInfo(res)
        } else {
          this.$emit("input", res.data.data);
        }

      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.getInfo();
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>