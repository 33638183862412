import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routeGenerator = function (className, title) {
  const upperCaseClassName = className.substring(0, 1).toUpperCase() + className.substring(1)
  return [
    {
      path: `/${className}`,
      name: `${className}`,
      component: () => import(`../views/${className}/${upperCaseClassName}.vue`),
      meta: {
        title,
        show: true,
      }
    },
    {
      path: `/${className}Edit`,
      name: `${className}Edit`,
      component: () => import(`../views/${className}/${upperCaseClassName}Edit.vue`),
      meta: {
        title,
      }
    }
  ];
};


const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'layoutHome',
    component: () => import('../layout/HomeView.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
          title: "首页",
          show: true,
        }
      },
      {
        path: '/toDo',
        name: 'toDo',
        component: () => import('../views/toDo/ToDo.vue'),
        meta: {
          title: "待办",
          show: true,
        }
      },
      {
        path: '日常打卡',
        component: () => import('../layout/IndexView.vue'),
        meta: {
          title: "日常打卡",
          show: true,
          children: true
        },
        children: [
          {
            path: '/dailyClockIn',
            name: 'dailyClockIn',
            component: () => import('../views/dailyClockIn/DailyClockIn.vue'),
            meta: {
              title: "打卡",
              show: true,
              children: true
            }
          },
          ...routeGenerator('clockItem', "日常项"),
          ...routeGenerator('clockTotal', "打卡统计"),
        ]
      },



      ...routeGenerator('function', "待开发功能"),
      ...routeGenerator('notifications', "通知管理"),

      {
        path: '系统管理',
        component: () => import('../layout/IndexView.vue'),
        meta: {
          title: "系统管理",
          show: true,
          children: true
        },
        children: [
          ...routeGenerator('log', "日志管理"),
          ...routeGenerator('dictionary', "字典管理"),
          ...routeGenerator('config', "配置管理"),
        ]
      },

      {
        path: '/game',
        name: 'game',
        component: () => import('../views/Game.vue'),
        meta: {
          title: "游戏",
          show: true,
        }
      },



    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 防止连续点击多次路由报错
let originPush = VueRouter.prototype.push;  //备份原push方法
VueRouter.prototype.push = function (location, resolve, reject) {
  // return routerPush.call(this, location).catch(err => err)
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, () => { }, () => { });
  }
}

//全局守卫
router.beforeEach((to, from, next) => {
  // //调往登录页面时 进行是否登录判断
  if (to.path === '/login') {

    if (localStorage.getItem("token")) {
      next("/home")
    } else {
      next()
    }
  } else {
    if (!localStorage.getItem("token")) {
      next("/login")
    } else {
      next()
    }
  }
});
export default router
