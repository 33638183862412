import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/filters'
import "@/api"
Vue.config.productionTip = false
import 'normalize.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import dayjs from 'dayjs'
import timezone from 'dayjs/esm/plugin/timezone/index'
import utc from 'dayjs/esm/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault("Asia/Shanghai")

import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'
// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer)

// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
// Vue.use(Viewer)
// Viewer.setDefaults({
//   Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
// })


import globalComponents from '@/globalComponents'
Vue.use(globalComponents)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
